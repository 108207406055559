<template>
	<v-container>
		<h1>Розклад</h1>
		<v-calendar
			ref="calendar"
			:events="events"
			color="primary"
			type="week"
			:weekdays="[1, 2, 3, 4, 5, 6, 0]"
			@click:event="showEvent">
		</v-calendar>
		<v-menu
			v-model="selectedOpen"
			:close-on-content-click="false"
			:activator="selectedElement"
			offset-xs>
			<v-card
				min-width="350px"
				outlined>
				<v-toolbar
					color="transparent"
					elevation="0"
				>
					<v-btn icon
						color="success"
						:to="{
							name: 'lecture',
							params: {
								lectureid: selectedEvent.id,
							},
						}">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon>
						<v-icon>mdi-heart</v-icon>
					</v-btn>
					<v-btn icon>
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</v-toolbar>
				<v-list>
					<v-list-item-group>
						<v-list-item v-for="user in selectedEvent.users"
							:key="user.id"
							:to="{
								name: 'user',
								params: {
									userid: user.id,
								},
							}"
							target="_blank">
							<v-list-item-avatar>
								<v-avatar color="teal" size="48">
									<span class="white--text">AN</span>
								</v-avatar>
							</v-list-item-avatar>

							<v-list-item-content>
								<v-list-item-title v-text="user.name"></v-list-item-title>
							</v-list-item-content>

							<v-list-item-icon>
								<!-- <v-icon :color="item.active ? 'deep-purple accent-4' : 'grey'">chat_bubble</v-icon> -->
							</v-list-item-icon>
						</v-list-item>
					</v-list-item-group>
				</v-list>
				<v-card-actions>
					<v-btn
						text
						color="secondary"
						@click="selectedOpen = false"
					>
						Cancel
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-menu>
	</v-container>
</template>

<script>
import { apiFactory } from '../api/apiFactory'

const lecturesApi = apiFactory.get('lectures')

export default {
	data: () => ({
		lectures: [],
		selectedEvent: {},
		selectedElement: null,
		selectedOpen: false,
	}),
	computed: {
		events() {
			return this.lectures.map(item => {
				const { datetime } = item
				return {
					...item,
					start: datetime,
					end: '2020-08-06 22:00',
				}
			})
		},
	},
	methods: {
		async loadLectures() {
			try {
				const { data } = await lecturesApi.getAll()
				this.lectures = data
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error)
			}
		},
		showEvent ({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event
				this.selectedElement = nativeEvent.target
				setTimeout(() => this.selectedOpen = true, 10)
			}

			if (this.selectedOpen) {
				this.selectedOpen = false
				setTimeout(open, 10)
			} else {
				open()
			}

			nativeEvent.stopPropagation()
		},
	},
	mounted() {
		this.loadLectures()
	},
}
</script>