var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h1',[_vm._v("Розклад")]),_c('v-calendar',{ref:"calendar",attrs:{"events":_vm.events,"color":"primary","type":"week","weekdays":[1, 2, 3, 4, 5, 6, 0]},on:{"click:event":_vm.showEvent}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-xs":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"min-width":"350px","outlined":""}},[_c('v-toolbar',{attrs:{"color":"transparent","elevation":"0"}},[_c('v-btn',{attrs:{"icon":"","color":"success","to":{
						name: 'lecture',
						params: {
							lectureid: _vm.selectedEvent.id,
						},
					}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-heart")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),_c('v-list',[_c('v-list-item-group',_vm._l((_vm.selectedEvent.users),function(user){return _c('v-list-item',{key:user.id,attrs:{"to":{
							name: 'user',
							params: {
								userid: user.id,
							},
						},"target":"_blank"}},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"teal","size":"48"}},[_c('span',{staticClass:"white--text"},[_vm._v("AN")])])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(user.name)}})],1),_c('v-list-item-icon')],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }